.App .ant-table-wrapper .ant-table-pagination {
    align-items: center;
}

.spin-center-fixed {
    position: fixed;
    top: 50%;
    left: 50%;
}

.ant-select.ant-select-multiple .ant-select-selector {
    padding: 2px 4px;
}
.ant-select.ant-select-multiple .ant-select-selection-item {
    height: 30px;
    line-height: 28px;
}
.ant-select.ant-select-multiple .ant-select-selector:after {
    height: 30px;
}

.App .ant-menu .ant-menu-item .ant-menu-title-content {
    font-size: 13px;
}

.App * {
    font-size: 13px !important;
}
.App .ant-spin .ant-spin-dot-spin {
    font-size: 24px !important;
}
.App .ant-spin-lg .ant-spin-dot {
    font-size: 40px !important;
}

.App .ant-card .ant-card-meta-title {
    word-break: break-all;
}
.App .channel-message-datetime {
    font-size: 12px!important;
}
.App .search-with-filters-card .search-with-filters-datetime {
    font-size: 12px!important;
}
